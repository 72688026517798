body {
  margin: 0;
  padding: 0;
}

h1 {
  text-align: center;
}

h2 {
  text-align: center;
}

header h4 {
  text-align: center;
}

main table {
  margin: 0 1vw;
  width: 97vw;
  border: black solid 1px;
}

main table tr th:first-of-type {
  width: 40vw;
}

main table tr th:last-of-type {
  width: 20vw;
}

main table tr td:nth-child(2),
main table tr td:last-of-type {
  text-align: center;
}

main table th {
  border: black solid 1px;
}

main table td {
  border: black solid 1px;
}
